import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
  CreateDailyReportComponent,
} from '../components/create-daily-report/create-daily-report.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectsService} from '../core/projects.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  BidItemDetailsWrapperComponent
} from '../pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/bid-item-details-wrapper/bid-item-details-wrapper.component';

@Component({
  selector: 'app-projects-wrapper',
  templateUrl: './projects-wrapper.component.html',
  styleUrls: ['./projects-wrapper.component.scss'],
  animations: [
    trigger('leftAnimation', [
      state(
        '*',
        style({
          width: '100%',
        }),
      ),
      state(
        'sidenavOpen',
        style({
          width: 'calc(100% - 535px)',
        }),
      ),
      state(
        'sidenavClosed',
        style({
          width: '100%',
        }),
      ),
      transition('*=>sidenavOpen', animate('500ms')),
      transition('*=>sidenavClosed', animate('500ms')),
    ]),
    trigger('rightAnimation', [
      state(
        '*',
        style({
          width: '0px',
        }),
      ),
      state(
        'sidenavOpen',
        style({
          width: '535px',
        }),
      ),
      state(
        'sidenavClosed',
        style({
          width: '0px',
        }),
      ),
      transition('*=>sidenavOpen', animate('500ms')),
      transition('*=>sidenavClosed', animate('500ms')),
    ]),
    trigger('legendAnimation', [
      state(
        'visible',
        style({
          transform: 'translate(0%, 0%) scale(1)',
          opacity: 1,
        }),
      ),
      state(
        'void, hidden',
        style({
          transform: 'translate(-30%, 25%) scale(0.4)',
          opacity: 0,
        }),
      ),
      transition('* => visible', animate('250ms')),
      transition('* => void, * => hidden', animate('250ms')),
    ]),
  ],
})
export class ProjectsWrapperComponent implements OnDestroy {
  isFullScreen = false;
  showSidenav = false;
  @ViewChild('container', { read: ViewContainerRef }) container;
  private readonly onDestroy: Subject<any> = new Subject<any>();


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private resolver: ComponentFactoryResolver,
  ) {
    this.projectsService.closeCreateDailyReportDialog
      .pipe()
      .subscribe(() => {
        this.dialog.closeAll();
      });

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;
      });

    this.projectsService.openRightPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        this.showSidenav = true;
        setTimeout(() => {
          this.createComponent(params.component, params.data);
        }, 300);
      });

    this.projectsService.closeRightPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        this.showSidenav = false;
      });
  }

  close() {
    this.showSidenav = false;
    setTimeout(() => {
      this.container?.clear();
    }, 250);
  }

  createComponent(component, data) {
    this.container?.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component);
    const componentRef = this.container.createComponent(factory);
    componentRef.instance.data = data;
  }

  handleAdd() {
    const currentRoute = this.router.url.split('?')[0];

    if (currentRoute === '/projects') {
      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        data: { x: 3 },
        panelClass: 'floating-dialog-box',
      });
    }

    const projectDetailsPageUrlRegex = /^\/projects\/[a-zA-Z0-9]{20,25}$/;
    if (projectDetailsPageUrlRegex.test(currentRoute)) {
      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        panelClass: 'floating-dialog-box',
        data: {
          projectId: currentRoute.split('/').pop(),
        },
      });
    }

    const siteDetailsUrlRegex = /^\/projects\/[a-zA-Z0-9]{20,25}\/sites\/[a-zA-Z0-9]{20,25}$/;
    if (
      siteDetailsUrlRegex.test(currentRoute) &&
      this.route.snapshot.queryParams['tab'] &&
      this.route.snapshot.queryParams['tab'] === 'daily-report'
    ) {
      console.log(this.route.snapshot);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
