import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { AbilityModule } from '@casl/angular';
import {
  DateDurationFormatPipe,
  DateFormatPipe,
  DurationFormatPipe,
  FilterPipe,
  HumanReadablePipe,
  HumanReadableSizePipe,
} from 'src/app/shared/pipes';
import { MaterialModule } from '../material/material.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { httpInterceptorProviders } from './http-interceptors';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SwiperModule } from 'swiper/angular';
import { AppIconComponent } from './components/app-icon/app-icon.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import {
  ComponentDirective,
  ConfirmDialogComponent,
  EsriMapComponent,
  FileUploaderComponent,
  GoogleMapsComponent,
  SearchComponent,
  SidebarComponent,
} from './components';
import { EquipmentTypesComponent } from './components/equipment-components/equipment-types/equipment-types.component';
import { FieldInputComponent } from './components/fields/field-input/field-input.component';
import { FieldsComponent } from './components/fields/fields.component';
import { JobCodesComponent } from './components/labor-components/job-codes/job-codes.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';
import { MaterialTestsComponent } from './components/material-tests/material-tests.component';
import { ModalComponent, ModalHostDirective } from './components/modal/modal.component';
import { ProgressCardComponent } from './components/progress-card/progress-card.component';
import { ProjectCardComponent } from './components/project/project-card/project-card.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SubmittalPackageLogComponent } from './components/submittal-package-log/submittal-package-log.component';
import { TranslationsComponent } from './components/translations/translations.component';
import { WebViewerComponent } from './components/webviewer/webviewer.component';
import { WorkflowLineageComponent } from './components/workflow-lineage/workflow-lineage.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { SharedEffects } from './core/shared.effects';
import { reducer } from './core/shared.reducer';
import { MapPopupWrapperComponent } from './components/map-popup/map-popup-wrapper/map-popup-wrapper.component';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { PreventInputDirective } from './directives/prevent-input.directive';
import { MapPopupTypeOneComponent } from './components/map-popup/components/map-popup-type-one/map-popup-type-one.component';
import {
  MapPopupSummaryTypeOneComponent,
} from './components/map-popup/components/map-popup-summary-type-one/map-popup-summary-type-one.component';
import {
  MapPopupSummaryTypeTwoComponent,
} from './components/map-popup/components/map-popup-summary-type-two/map-popup-summary-type-two.component';
import { MapPopupTypeTwoComponent } from './components/map-popup/components/map-popup-type-two/map-popup-type-two.component';
import { ShimmerLoaderComponent } from './components/shimmer-loader/shimmer-loader.component';
import { ImageComponent } from './components/image/image.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ViewFieldComponent } from './components/form-fields/view-field/view-field.component';
import { CarouselTypeTwoComponent } from './components/carousel-type-two/carousel-type-two.component';
import { DummyComponent } from './components/dummy/dummy.component';
import { DropdownComponent } from './components/form-fields/input/dropdown/dropdown.component';
import { PrimaryButtonComponent } from './components/buttons/primary-button/primary-button.component';
import { PreventMultipleClickDirective } from './directives/prevent-multiple-click.directive';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DatePickerComponent } from './components/form-fields/input/date-picker/date-picker.component';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatRippleModule } from '@angular/material/core';
import { DateDirective } from './directives/date.directive';
import { DatePickerFormatPipe } from './pipes/date-picker-format.pipe';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { CheckBoxComponent } from './components/form-fields/input/check-box/check-box.component';
import { SecondaryButtonComponent } from './components/buttons/secondary-button/secondary-button.component';
import { TextAreaComponent } from './components/form-fields/input/text-area/text-area.component';
import { HorizontalDatePickerComponent } from './components/form-fields/input/horizontal-date-picker/horizontal-date-picker.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import {
  CheckBoxListWithFilterComponent,
} from './components/form-fields/input/check-box-list-with-filter/check-box-list-with-filter.component';
import { TextAreaTypeTwoComponent } from './components/form-fields/input/text-area-type-two/text-area-type-two.component';
import { TextInputComponent } from './components/form-fields/input/text-input/text-input.component';
import { NumberInputComponent } from './components/form-fields/input/number-input/number-input.component';
import { AutocompleteComponent } from './components/form-fields/input/autocomplete/autocomplete.component';
import {
} from './components/form-fields/input/check-box-list-with-filter/check-box-list-with-filter.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HumanReadablePipe,
    HumanReadableSizePipe,
    DateFormatPipe,
    DurationFormatPipe,
    DateDurationFormatPipe,
    FilterPipe,
    FileUploaderComponent,
    ConfirmDialogComponent,
    SearchComponent,
    GoogleMapsComponent,
    SidebarComponent,
    ModalComponent,
    ComponentDirective,
    ModalHostDirective,
    WebViewerComponent,
    WorkflowComponent,
    WorkflowLineageComponent,
    AppIconComponent,
    LocationPickerComponent,
    CarouselComponent,
    ProgressCardComponent,
    ProjectCardComponent,
    SubmittalPackageLogComponent,
    FieldsComponent,
    ReportsComponent,
    FieldInputComponent,
    TranslationsComponent,
    MaterialTestsComponent,
    JobCodesComponent,
    EquipmentTypesComponent,
    EsriMapComponent,
    MapPopupWrapperComponent,
    OnlyDigitsDirective,
    PreventInputDirective,
    MapPopupTypeOneComponent,
    MapPopupSummaryTypeOneComponent,
    MapPopupSummaryTypeTwoComponent,
    MapPopupTypeTwoComponent,
    ImageComponent,
    ShimmerLoaderComponent,
    ViewFieldComponent,
    CarouselTypeTwoComponent,
    LoadingScreenComponent,
    DummyComponent,
    DropdownComponent,
    PrimaryButtonComponent,
    PreventMultipleClickDirective,
    DatePickerComponent,
    DateDirective,
    DatePickerFormatPipe,
    CheckBoxComponent,
    SecondaryButtonComponent,
    TextAreaComponent,
    HorizontalDatePickerComponent,
    NumberInputComponent,
    AutocompleteComponent,
    PaginatorComponent,
    CheckBoxListWithFilterComponent,
    TextAreaTypeTwoComponent,
    TextInputComponent,
    CheckBoxListWithFilterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AbilityModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    SwiperModule,
    NgxSkeletonLoaderModule,
    StoreModule.forFeature('sitenotes.shared', reducer),
    EffectsModule.forFeature([SharedEffects]),
    MatLegacyTooltipModule,
    MatSnackBarModule,
    MatDatetimepickerModule,
    MatRippleModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    HumanReadablePipe,
    HumanReadableSizePipe,
    DateFormatPipe,
    DurationFormatPipe,
    DateDurationFormatPipe,
    FilterPipe,
    MaterialModule,
    AbilityModule,
    FileUploaderComponent,
    ConfirmDialogComponent,
    SearchComponent,
    GoogleMapsComponent,
    SidebarComponent,
    ModalComponent,
    WebViewerComponent,
    WorkflowComponent,
    WorkflowLineageComponent,
    AppIconComponent,
    NgxMaterialTimepickerModule,
    SwiperModule,
    LocationPickerComponent,
    CarouselComponent,
    ProgressCardComponent,
    ProjectCardComponent,
    SubmittalPackageLogComponent,
    FieldsComponent,
    ReportsComponent,
    FieldInputComponent,
    TranslationsComponent,
    MaterialTestsComponent,
    JobCodesComponent,
    EquipmentTypesComponent,
    EsriMapComponent,
    MapPopupWrapperComponent,
    OnlyDigitsDirective,
    PreventInputDirective,
    ImageComponent,
    ViewFieldComponent,
    CarouselTypeTwoComponent,
    LoadingScreenComponent,
    DropdownComponent,
    PrimaryButtonComponent,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    DatePickerComponent,
    CheckBoxComponent,
    SecondaryButtonComponent,
    TextAreaComponent,
    HorizontalDatePickerComponent,
    NumberInputComponent,
    AutocompleteComponent,
    PaginatorComponent,
    CheckBoxListWithFilterComponent,
    TextAreaTypeTwoComponent,
    TextInputComponent,
    CheckBoxListWithFilterComponent,
  ],
  providers: [
    DatePipe,
    DatePickerFormatPipe,
    httpInterceptorProviders,
  ],
})
export class SharedModule {
}
