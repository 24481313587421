<div
  [class.map-full-screen]="isFullScreen"
  class="wrapper"
>
  <div
    [@leftAnimation]="showSidenav ? 'sidenavOpen' : 'sidenavClosed'"
    class="left"
  >
    <app-map-wrapper class="map">
      <router-outlet name="map"></router-outlet>
    </app-map-wrapper>
    <div
      (click)="handleAdd()"
      *ngIf="!isFullScreen"
      class="add-button"
    >
      <mat-icon svgIcon="add" />
    </div>
    <div
      *ngIf="!isFullScreen"
      [class.sidenav-open]="showSidenav"
      class="content"
    >
      <router-outlet name="content" />
    </div>
  </div>
  <div
    *ngIf="showSidenav"
    [@rightAnimation]="showSidenav ? 'sidenavOpen' : 'sidenavClosed'"
    class="right"
  >
    <div class="close-block">
      <mat-icon
        (click)="close()"
        class="close-icon"
        svgIcon="close"
      />
    </div>
    <template #container></template>
  </div>
</div>
