import { NgModule } from '@angular/core';

import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';

@NgModule({
  exports: [
    MatInputModule,
    MatSliderModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatDatetimepickerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatChipsModule,
    DragDropModule,
    MatListModule,
    MatAutocompleteModule,
    MatStepperModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class MaterialModule {
  private icons = [
    // { name: 'sn-thumbs-up', path: 'sn_thumbs_up_24px.svg' },
    // { name: 'sn-station', path: 'sn_station.svg' }
    { name: 'activities', path: 'svg/activities.svg' },
    { name: 'bid-item', path: 'svg/bid-item.svg' },
    { name: 'calendar', path: 'svg/calendar.svg' },
    { name: 'camera-outline', path: 'svg/camera-outline.svg' },
    { name: 'chevron-down', path: 'svg/chevron-down.svg' },
    { name: 'chevron-up', path: 'svg/chevron-up.svg' },
    { name: 'close', path: 'svg/close.svg' },
    { name: 'chevron-right', path: 'svg/chevron-right.svg' },
    { name: 'chevron-right', path: 'svg/chevron-right.svg' },
    { name: 'chevron-right-disabled', path: 'svg/chevron-right-disabled.svg' },
    { name: 'close', path: 'svg/close.svg' },
    { name: 'closed-issues', path: '/closed-issues.svg' },
    { name: 'daily-report-activity', path: 'svg/daily-report/daily-report-activity.svg' },
    { name: 'daily-report-bid-item', path: 'svg/daily-report/daily-report-bid-item.svg' },
    { name: 'daily-report-environmental', path: 'svg/daily-report/daily-report-environmental.svg' },
    { name: 'daily-report-issues', path: 'svg/daily-report/daily-report-issues.svg' },
    { name: 'daily-report-labor-equipment', path: 'svg/daily-report/daily-report-labor-equipment.svg' },
    { name: 'daily-report-material-delivery', path: 'svg/daily-report/daily-report-material-delivery.svg' },
    { name: 'daily-report-material-testing', path: 'svg/daily-report/daily-report-material-testing.svg' },
    { name: 'daily-report-safety', path: 'svg/daily-report/daily-report-safety.svg' },
    { name: 'daily-report-weather', path: 'svg/daily-report/daily-report-weather.svg' },
    { name: 'delivery', path: 'svg/delivery.svg' },
    { name: 'dot', path: 'svg/dot.svg' },
    { name: 'error', path: 'svg/error.svg' },
    { name: 'filters', path: 'svg/filters.svg' },
    { name: 'flag', path: 'svg/flag.svg' },
    { name: 'full-screen', path: 'svg/full-screen.svg' },
    { name: 'issues', path: 'svg/issues.svg' },
    { name: 'labor-equipment', path: 'svg/labor-equipment.svg' },
    { name: 'level-up', path: 'svg/level-up.svg' },
    { name: 'marker-outline', path: 'svg/marker-outline.svg' },
    { name: 'minus', path: 'svg/minus.svg' },
    { name: 'no-location', path: 'svg/no-location.svg' },
    { name: 'open-issues', path: '/open-issues.svg' },
    { name: 'paperclip', path: '/paperclip.svg' },
    { name: 'pencil-outline', path: 'svg/pencil-outline.svg' },
    { name: 'plus', path: 'svg/plus.svg' },
    { name: 'quality', path: 'svg/quality.svg' },
    { name: 'search', path: '/search.svg' },
    { name: 'sn-labor', path: '/sn_labor.svg' },
    { name: 'success-check', path: 'svg/success-check.svg' },
    { name: 'trash', path: 'svg/trash.svg' },
    { name: 'white-tick-with-green-background', path: 'svg/white-tick-with-green-background.svg' },
    { name: 'sn-labor', path: 'sn_labor.svg' },
    { name: 'closed-issues', path: 'closed-issues.svg' },
    { name: 'open-issues', path: 'open-issues.svg' },
    { name: 'add', path: 'add.svg' },
  ];

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    // register custom icons from svg files
    this.icons.map(ic => {
      iconRegistry.addSvgIcon(
        ic.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${ic.path}`));
    });
  }
}
