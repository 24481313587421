import { environment } from '../../../environments/environment';

const baseURL = environment.API_ENDPOINT;

const v1Base = `${baseURL}/v1`;
const v2Base = `${baseURL}/v2`;

function toQueryString(payload: any): string {
  const parts: string[] = [];

  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      const value = payload[key];

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          parts.push(`${encodeURIComponent(key)}[${index}]=${encodeURIComponent(item)}`);
        });
      } else {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return parts.join('&');
}

const serializeQueryParams = (obj, prefix = null) => {
  const str = [];
  for (let [k, v] of Object.entries(obj || {})) {
    if (v === undefined) {
      continue;
    }
    k = prefix ? `${prefix}[${k}]` : k;

    const value = typeof v === 'object'
      ? serializeQueryParams(v, k)
      : `${k}=${v}`;
    str.push(value);

  }
  return str.join('&');
};

export const v1URL = {
  appData: {
    method: 'get',
    url: `${v1Base}/appdata`,
  },
  project: {
    method: 'get',
    url: params => `${v1Base}/project?${serializeQueryParams(params.qp)}`,
  },
  issue: {
    method: 'get',
    url: params => `${v1Base}/issue/summary/${params.entityType}/${params.project.id}`,
  },
  dailyReports: {
    fetchByProject: {
      method: 'get',
      url: params => `${v1Base}/daily-report/project/${params.project.id}?${serializeQueryParams(params.qp)}`,
    },
  },
  dailyReport: {
    fetch: {
      method: 'get',
      url: params => `${v1Base}/daily-report/${params.reportId}?${serializeQueryParams(params.qp)}`,
    },
    create: {
      method: 'post',
      url: params => `${v1Base}/daily-report/${params.siteId}/draft`,
    },
  },
  getProjectDetailsById: {
    method: 'get',
    url: params => `${v1Base}/project/${params.projectId}?${serializeQueryParams(params.qp)}`,
  },
  issues: {
    method: 'get',
    url: params => `${v1Base}/issue/?${serializeQueryParams(params.qp)}`,
  },
  projectReports: {
    fetch: {
      method: 'get',
      url: params => `${v1Base}/project-report?${serializeQueryParams(params.qp)}`,
    },
  },
  projectMembers: {
    fetch: {
      method: 'get',
      url: params => `${v1Base}/project/${params.projectId}/accounts`,
    },
  },

  siteDetails: {
    fetch: {
      method: 'get',
      url: params => `${v1Base}/site/${params.siteId}?${serializeQueryParams(params.qp)}`,
    },
  },

  attachment: {
    method: 'post',
    url: `${v1Base}/attachment`,
  },
  bidSheet: {
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/bid/${params.projectId}/sheet?`,
    },
    upload: {
      method: 'post',
      url: (params) => `${v1Base}/bid/sheet/${params.bidSheetId}/csv`,
    },
    fetchTemplateFile: {
      method: 'get',
      url: (params) => `${v1Base}/bid/sheet/${params.bidSheetId}/csv-template`,
    },
  },
  bidItemsList: {
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/bid/sheet/${params.bidSheetId}/item?${serializeQueryParams(params.qp)}`,
    },
  },
  bidItemSitesList: {
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/bid/item/${params.bidItemId}?${serializeQueryParams(params.qp)}`,
    },
  },
  changeOrders: {
    fetch: {
      method: 'get',
      url: (params) => `${v1Base}/bid/${params.projectId}/change-order`,
    },
    fetchBidItemList: {
      method: 'get',
      url: (params) => `${v1Base}/bid/${params.projectId}/change-order/${params.changeOrderId}?${serializeQueryParams(params.qp)}`,
    },
    fetchChangeOrderFiltersInContextOfBidItems: {
      method: 'get',
      url: (params) => `${v1Base}/bid/item/${params.bidItemId}?${serializeQueryParams(params.qp)}`,
    },
  },
  payapp: {
    fetchPayappItems: {
      method: 'get',
      url: params => `${v2Base}/project/${params.projectId}/pay-app?${serializeQueryParams(params.qp)}`,
    },
    create: {
      method: 'post',
      url: params => `${v2Base}/project/${params.projectId}/pay-app`,
    },
    fetchPayapps: {
      method: 'get',
      url: params => `${v2Base}/project/${params.projectId}/pay-apps`,
    },
    fetchPayappDetails: {
      method: 'get',
      url: params => `${v2Base}/project/${params.projectId}/pay-app/${params.payappId}?${serializeQueryParams(params.qp)}`,
    },
    updatePayappStatus: {
      method: 'patch',
      url: params => `${v1Base}/daily-report/${params.dailyReportId}/report-bid-item/${params.reportBidItemId}/pay-app-status`,
    },
    delete: {
      method: 'delete',
      url: params => `${v2Base}/project/${params.projectId}/pay-app/${params.payappId}`,
    },
  },
};


export const v2URL = {
  siteWiseDailyReportSummary: {
    fetch: {
      method: 'get',
      url: params => `${v2Base}/project/${params.projectId}/daily-report-summary`,
    },
  },
};
